import { LandmarkInput } from '@sim-admin-frontends/data-access-admin-be';
import { prepareFileObjects, TUploadImagesFunction } from '@sim-admin-frontends/utils-shared';
import { UploadType } from '@sim-admin-frontends/data-access';

import { TLandmarkEditFormValues } from '../types/TLandmark';

export const prepareBackendData = async (
  formValues: TLandmarkEditFormValues,
  placeId: string,
  uploadFormImages: TUploadImagesFunction,
): Promise<LandmarkInput> => {
  const transformedData: LandmarkInput = {
    placeUuid: placeId,
    id: formValues.id,
    title: formValues.title,
    additionalType: formValues.additionalType,
    rating: formValues.rating,
    userRatingCount: formValues.userRatingCount,
    googlePlacesId: formValues.googlePlacesId,
    gpsLocation: formValues.gpsLocation,
    imageObjects: await prepareLandmarImageObjectsUpdateData(formValues, placeId, uploadFormImages),
    initials: formValues.initials,
    isHiddenGem: formValues.isHiddenGem,
    primaryType: formValues.primaryType,
    types: formValues.types,
    description: formValues.description,
  };
  return transformedData;
};

const prepareLandmarImageObjectsUpdateData = async (
  formValues: TLandmarkEditFormValues,
  placeId: string,
  uploadFormImages: TUploadImagesFunction,
) => {
  const imageObjects = await uploadFormImages(UploadType.Landmark, formValues.imageObjects, {
    placeId,
  });
  return prepareFileObjects(imageObjects);
};
