import {
  PlaceInput,
  RestaurantCuisine,
  TSAPlaceListItems,
} from '@sim-admin-frontends/data-access-admin-be';
import { SERIALIZED_WYSIWYG_EMPTY_VALUE } from '@sim-admin-frontends/ui-shared';
import {
  prepareFileObjects,
  transformEmptyHelper,
  TUploadImages,
  TUploadImagesFunction,
} from '@sim-admin-frontends/utils-shared';
import { UploadType } from '@sim-admin-frontends/data-access';

import { TAddPlaceFormValues, TPlaceEnablingStatus } from '../types/TPlace';

export const prepareCreationData = (formValues: TAddPlaceFormValues): PlaceInput => {
  const transformedData: PlaceInput = {
    name: formValues.name,
    countryName: formValues.countryName,
    borough: formValues.borough,
    countryCode: formValues.countryCode,
    stateName: formValues.stateName,
    stateCode: formValues.stateCode,
    gps: formValues.gps,
    hasDistricts: formValues.hasDistricts || false,
    parentPlaceUuid: formValues.parentPlaceUuid?.value
      ? `place#${formValues.parentPlaceUuid?.value}`
      : undefined,
    timezoneCode: formValues.timezoneCode,
    info: {
      description: transformEmptyHelper(formValues.description),
      about:
        formValues.about && formValues.about !== SERIALIZED_WYSIWYG_EMPTY_VALUE
          ? formValues.about
          : null,
      aboutHeader: transformEmptyHelper(formValues.aboutHeader),
      reportsEmail: transformEmptyHelper(formValues.reportsEmail),
    },
    additionalGroupsOfInstitutions: formValues.additionalGroupsOfInstitutions,
    branding: null,
    mapLimits: {
      announcementRangeInHours: Number(formValues.announcementRange) || undefined,
      eventRangeInHours: Number(formValues.eventRange) || undefined,
      centerMapToCityIfDistanceLessThanKm: Number(formValues.centerMapMinDistance) || undefined,
    },
    visitorWelcomeVideoUrl: formValues?.visitorWelcomeVideoUrl,
    isVisitorModeAvailable: formValues.visitorMode,
    disabledContent: formValues.disabledContent,
    localCuisines: formValues.localCuisines?.map((cuisine) => cuisine.value) as RestaurantCuisine[],
    isItineraryModeAvailable: formValues.itineraryMode,
    popularDestination: formValues.popularDestination,
    isResidentModeAvailable: formValues.residentMode,
    isOgCity: formValues.isOgCity,
    trafficAvgSpeed: formValues.trafficAvgSpeed,
  };
  return transformedData;
};

const prepareBrandingUpdateData = async (
  formValues: TAddPlaceFormValues,
  placeId: string,
  uploadFormImages: TUploadImagesFunction,
) => {
  const logoImage = await uploadFormImagesIfFileExists(
    UploadType.PlaceLogo,
    formValues.branding?.logo,
    placeId,
    uploadFormImages,
  );
  const avatarImage = await uploadFormImagesIfFileExists(
    UploadType.PlaceAvatar,
    formValues.branding?.avatar,
    placeId,
    uploadFormImages,
  );
  const backgroundImage = await uploadFormImagesIfFileExists(
    UploadType.PlaceBackground,
    formValues.branding?.background,
    placeId,
    uploadFormImages,
  );
  const topTenBackgroundImage = await uploadFormImagesIfFileExists(
    UploadType.PlaceTopTenBackground,
    formValues.branding?.topTenBackground,
    placeId,
    uploadFormImages,
  );
  const logo = prepareFileObjects(logoImage);
  const avatar = prepareFileObjects(avatarImage);
  const background = prepareFileObjects(backgroundImage);
  const topTenBackground = prepareFileObjects(topTenBackgroundImage);

  return {
    logo: logo?.[0],
    avatar: avatar?.[0],
    background: background?.[0],
    topTenBackground: topTenBackground?.[0],
  };
};

const uploadFormImagesIfFileExists = async (
  type: UploadType,
  images: TUploadImages,
  placeId: string,
  uploadFormImages: TUploadImagesFunction,
) => {
  if (images && images.length > 0) {
    return await uploadFormImages(type, images, { placeId });
  }
  return undefined;
};

const prepareSectionImagesUpdateData = async (
  formValues: TAddPlaceFormValues,
  placeId: string,
  uploadFormImages: TUploadImagesFunction,
) => {
  const attractionsImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.sectionImages?.attractions,
    placeId,
    uploadFormImages,
  );
  const museumsImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.sectionImages?.museums,
    placeId,
    uploadFormImages,
  );
  const theatreImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.sectionImages?.theatre,
    placeId,
    uploadFormImages,
  );
  const concertsImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.sectionImages?.concerts,
    placeId,
    uploadFormImages,
  );
  const sportsImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.sectionImages?.sports,
    placeId,
    uploadFormImages,
  );
  const cellImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.sectionImages?.cell,
    placeId,
    uploadFormImages,
  );

  const attractions = prepareFileObjects(attractionsImages);
  const museums = prepareFileObjects(museumsImages);
  const theatre = prepareFileObjects(theatreImages);
  const concerts = prepareFileObjects(concertsImages);
  const sports = prepareFileObjects(sportsImages);
  const cell = prepareFileObjects(cellImages);

  return {
    attractions: attractions?.[0],
    museums: museums?.[0],
    theatre: theatre?.[0],
    concerts: concerts?.[0],
    sports: sports?.[0],
    cell: cell?.[0],
  };
};

const prepareDashboardImagesUpdateData = async (
  formValues: TAddPlaceFormValues,
  placeId: string,
  uploadFormImages: TUploadImagesFunction,
) => {
  const forYouImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.dashboardImages?.forYou,
    placeId,
    uploadFormImages,
  );
  const landmarksImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.dashboardImages?.landmarks,
    placeId,
    uploadFormImages,
  );
  const restaurantsImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.dashboardImages?.restaurants,
    placeId,
    uploadFormImages,
  );
  const attractionsImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.dashboardImages?.attractions,
    placeId,
    uploadFormImages,
  );
  const eventsImages = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.dashboardImages?.events,
    placeId,
    uploadFormImages,
  );

  const forYou = prepareFileObjects(forYouImages);
  const landmarks = prepareFileObjects(landmarksImages);
  const restaurants = prepareFileObjects(restaurantsImages);
  const attractions = prepareFileObjects(attractionsImages);
  const events = prepareFileObjects(eventsImages);

  return {
    forYou,
    landmarks,
    restaurants,
    attractions,
    events,
  };
};

export const prepareUpdateData = async (
  createdData: PlaceInput,
  formValues: TAddPlaceFormValues,
  placeId: string,
  uploadFormImages: TUploadImagesFunction,
): Promise<PlaceInput> => {
  const badgesImageImage = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.badgesImage,
    placeId,
    uploadFormImages,
  );

  const badgesImage = prepareFileObjects(badgesImageImage);

  const welcomeScreenImage = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.welcomeScreenImage,
    placeId,
    uploadFormImages,
  );

  const welcomeScreen = prepareFileObjects(welcomeScreenImage);

  const mapIntroImage = await uploadFormImagesIfFileExists(
    UploadType.Place,
    formValues.mapIntroImage,
    placeId,
    uploadFormImages,
  );
  const mapIntro = prepareFileObjects(mapIntroImage);

  return {
    ...createdData,
    branding: await prepareBrandingUpdateData(formValues, placeId, uploadFormImages),
    sectionImages: await prepareSectionImagesUpdateData(formValues, placeId, uploadFormImages),
    dashboardImages: await prepareDashboardImagesUpdateData(formValues, placeId, uploadFormImages),
    badgesImage: badgesImage[0] || null,
    welcomeScreenImage: welcomeScreen[0] || null,
    mapIntroImage: mapIntro[0] || null,
  };
};

export const getIsPlaceEnabled = (place: TSAPlaceListItems[number]) => place.enabled === 'true';

export const transformPlaces = (places: TSAPlaceListItems) =>
  places.map((place) => ({
    label: place.name,
    value: place.id,
    disabled: place.enabled === TPlaceEnablingStatus.DISABLED,
  }));
