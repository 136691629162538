import { yupResolver } from '@hookform/resolvers/yup';
import {
  RestaurantCuisine,
  TAdminUserListItem,
  TAdminUsersList,
  TSAInstitutionListItem,
  TSAInstitutionListItems,
} from '@sim-admin-frontends/data-access';
import {
  ImageInput,
  InstitutionPlaceRole,
  PlaceContent,
  TSAPlaceDetail,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  Button,
  FormInput,
  FormSectionHeader,
  FormSelect,
  FormSwitcherWithLabel,
  FormTextarea,
  FormUpload,
  FormWysiwyg,
  MAX_BRANDING_UPLOAD_SIZE,
  MAX_UPLOAD_SIZE,
  SERIALIZED_WYSIWYG_EMPTY_VALUE,
  SUPPORTED_WEB_IMAGE_FORMATS,
  TSearchItem,
  TSelectItem,
  TSelectItems,
  TSelectRef,
} from '@sim-admin-frontends/ui-shared';
import { formatDateObject, isEmpty, isFile } from '@sim-admin-frontends/utils-shared';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, get, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import ROUTES from '../../../routing/routes';
import { TAddPlaceFormValues } from '../../../types/TPlace';
import {
  getInitialPlaceBrandingValues,
  getParentPlaceUuidFromString,
  getInitialPlaceSectionImages,
} from '../../../utils/formUtils';
import { transformArrayToSelectItems } from '../../../utils/selectUtils';
import { ButtonsWrapper, Wrapper } from '../../common/Formstyles';
import { PlaceSearchInput } from '../../common/PlaceSearchInput';
import { URL_REGEX } from '../../placeFeatures/edit/PlaceFeatureEdit';

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.spacing24};
  max-width: 960px;
  min-width: 700px;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const PlaceEditWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.coolGray10};
  padding: ${({ theme }) => theme.spaces.spacing24};
`;

const PlaceDataText = styled.pre`
  width: 100%;
  white-space: pre-wrap;
`;

const VerticalDivider = styled.div`
  width: ${({ theme }) => theme.spaces.spacing32};
`;

const POSSIBLE_INSTITUTION_ROLES = Object.values(InstitutionPlaceRole).filter(
  (role) =>
    ![
      InstitutionPlaceRole.City,
      InstitutionPlaceRole.Official,
      InstitutionPlaceRole.Unofficial,
    ].includes(role),
);
const POSSIBLE_PLACE_CONTENT_CATEGORIES = Object.values(PlaceContent);

const schema = (t: TFunction, isDistrict: boolean) => {
  return Yup.object().shape({
    name: Yup.string().required(t('form.fieldRequired')),
    branding: Yup.object().shape({
      logo: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.file.tooLarge'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.png'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
      avatar: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.branding.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_BRANDING_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.branding.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
      background: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.branding.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_BRANDING_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.branding.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
    }),
    parentPlaceUuid: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .nullable()
      .test('fieldRequired', t('form.fieldRequired'), (value?: TSelectItem | null) => {
        if (!isDistrict) {
          return true;
        }

        return !!value?.value;
      }),
    visitorWelcomeVideoUrl: Yup.string().matches(URL_REGEX, t('form.urlFormat')).nullable(),
    badgesImage: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
        )
        .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
          file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
        ),
    ),
    sectionImages: Yup.object().shape({
      attractions: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
      museums: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
      theatre: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
      concerts: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
      sports: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
      cell: Yup.array().of(
        Yup.mixed()
          .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
          )
          .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
            file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
          ),
      ),
    }),
    welcomeScreenImage: Yup.array().of(
      Yup.mixed()
        .test('fileSize', t('common.validation.image.illegal'), (file: File | ImageInput) =>
          file && isFile(file) ? file.size <= MAX_UPLOAD_SIZE : true,
        )
        .test('fileType', t('common.validation.image.illegal'), (file: File | ImageInput) =>
          file && isFile(file) ? SUPPORTED_WEB_IMAGE_FORMATS.includes(file.type) : true,
        ),
    ),
    trafficAvgSpeed: Yup.number().nullable(),
  });
};

type PlaceEditProps = {
  onSubmit: (values: TAddPlaceFormValues) => Promise<void>;
  place?: TSAPlaceDetail;
  places?: TSelectItems;
  institutions?: TSAInstitutionListItems;
  users?: TAdminUsersList;
  onPlacesMenuScrollToBottom?: (e: Event) => void;
  isLoadingPlaces?: boolean;
  onPlacesSearch: (text: string) => void;
};

const PlaceEdit: FC<PlaceEditProps> = ({
  onSubmit,
  place,
  places,
  institutions,
  users,
  onPlacesMenuScrollToBottom,
  isLoadingPlaces,
  onPlacesSearch,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const initialParentPlaceUuid = getParentPlaceUuidFromString(place?.parentPlaceUuid);
  const initialValues: TAddPlaceFormValues = {
    name: place?.name || '',
    timezoneCode: place?.timezoneCode || '',
    stateName: place?.stateName || '',
    stateCode: place?.stateCode || '',
    countryName: place?.countryName || '',
    countryCode: place?.countryCode || '',
    hasDistricts: place?.hasDistricts || false,
    parentPlaceUuid: transformArrayToSelectItems(
      places?.filter((p) => p.value === initialParentPlaceUuid),
      'label',
      'value',
      true,
    )[0],
    borough: place?.borough || undefined,
    gps: place?.gps || { lat: 0, lon: 0 },
    branding: getInitialPlaceBrandingValues(place?.branding),
    reportsEmail: place?.info?.reportsEmail,
    about: place?.info?.about,
    aboutHeader: place?.info?.aboutHeader,
    description: place?.info?.description,
    additionalGroupsOfInstitutions: place?.additionalGroupsOfInstitutions || [],
    announcementRange: place?.mapLimits?.announcementRangeInHours || undefined,
    eventRange: place?.mapLimits?.eventRangeInHours || undefined,
    centerMapMinDistance: place?.mapLimits?.centerMapToCityIfDistanceLessThanKm || 30,
    visitorWelcomeVideoUrl: place?.visitorWelcomeVideoUrl ?? null,
    visitorMode: place?.isVisitorModeAvailable || false,
    disabledContent: place?.disabledContent || [],
    localCuisines: (place?.localCuisines || []).map((cuisine) => ({
      label: cuisine,
      value: cuisine,
    })),
    badgesImage: place?.badgesImage ? [place.badgesImage] : undefined,
    sectionImages: getInitialPlaceSectionImages(place?.sectionImages),
    welcomeScreenImage: place?.welcomeScreenImage ? [place.welcomeScreenImage] : undefined,
    itineraryMode: place?.isItineraryModeAvailable || false,
    popularDestination: place?.popularDestination || false,
    residentMode: place?.isResidentModeAvailable || false,
    isOgCity: place?.isOgCity || false,
    trafficAvgSpeed: place?.trafficAvgSpeed || 50,
    dashboardImages: place?.dashboardImages ?? undefined,
    mapIntroImage: place?.mapIntroImage ? [place.mapIntroImage] : undefined,
  };

  const [isPlaceDistrict, setIsPlaceDistrict] = useState<boolean>(
    !!getParentPlaceUuidFromString(place?.parentPlaceUuid),
  );

  const submit = (values: TAddPlaceFormValues) => {
    if (onSubmit) {
      return onSubmit(values);
    }
    return null;
  };

  const methods = useForm<TAddPlaceFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t, isPlaceDistrict)),
    mode: 'all',
  });

  const onIsDistrictSwitchChange = (value: boolean) => {
    setValue('hasDistricts', false);
    setIsPlaceDistrict(value);
    setValue('parentPlaceUuid', undefined);
    setValue('borough', undefined);
  };

  const onHasDistrictsSwitchChange = (value: boolean) => {
    setIsPlaceDistrict(false);
    setValue('hasDistricts', value);
    if (!value) {
      setValue('parentPlaceUuid', undefined);
      setValue('borough', undefined);
    }
  };

  const onVisitorModeSwitchChange = (value: boolean) => {
    setValue('visitorMode', value);
  };

  const onItineraryModeSwitchChange = (value: boolean) => {
    setValue('itineraryMode', value);
  };

  const onResidentModeSwitchChange = (value: boolean) => {
    setValue('residentMode', value);
  };

  const onIsOgCitySwitchChange = (value: boolean) => {
    setValue('isOgCity', value);
  };

  const onPopularDestinationSwitchChange = (value: boolean) => {
    setValue('popularDestination', value);
  };

  const onChangeInstitution = (group: InstitutionPlaceRole) => (isOn: boolean) => {
    setValue(
      'additionalGroupsOfInstitutions',
      isOn
        ? [...(currentGroupsOfInstitutions || []), group]
        : currentGroupsOfInstitutions?.filter((v) => v !== group),
    );
  };

  const onChangeDisabledGroupOfInstitution = (group: PlaceContent) => (isOn: boolean) => {
    setValue(
      'disabledContent',
      isOn
        ? [...(currentDisabledContent || []), group]
        : currentDisabledContent?.filter((v) => v !== group),
    );
  };

  const getParentPlaceUuidWithPrefix = (value?: string | undefined) => {
    if (!value) {
      return '';
    }
    if (!value.includes('place#')) {
      return `place#${value}`;
    }
    return value;
  };

  useEffect(() => {
    trigger('parentPlaceUuid');
    trigger('borough');
  }, [isPlaceDistrict]);

  const { handleSubmit, formState, setValue, control, trigger, register, getValues } = methods;
  const { errors, isSubmitting } = formState;
  const {
    hasDistricts: currentHasDistrictsValue,
    parentPlaceUuid: currentParentPlaceUuid,
    additionalGroupsOfInstitutions: currentGroupsOfInstitutions,
    disabledContent: currentDisabledContent,
  } = getValues();

  const [
    initialName,
    initialTimezoneCode,
    initialStateName,
    initialStateCode,
    initialCountryCode,
    initialCountryName,
    initialHasDistrict,
    initalGroupsOfInstitutions,
    initialDisabledContent,
  ] = useWatch({
    name: [
      'name',
      'timezoneCode',
      'stateName',
      'stateCode',
      'countryCode',
      'countryName',
      'hasDistricts',
      'additionalGroupsOfInstitutions',
      'disabledContent',
    ],
    control,
  });
  const [placeData, setPlaceData] = useState<TAddPlaceFormValues | null | undefined>(
    place ? initialValues : null,
  );

  const defaultPlaceUsers = useMemo(
    () => transformArrayToSelectItems<TAdminUserListItem>(users, 'username', 'username'),
    [users],
  );

  const defaultPlaceInstitutions = useMemo(
    () => transformArrayToSelectItems<TSAInstitutionListItem>(institutions, 'name', 'id', true),
    [institutions],
  );

  const redirectToInstitutionEdit = (data: TSearchItem) => {
    history.push(generatePath(ROUTES.institutionsEdit.path, { id: data.data.value }));
  };

  const redirectToUserEdit = (data: TSearchItem) => {
    history.push(generatePath(ROUTES.userEdit.path, { username: data.data.value }));
  };

  useEffect(() => {
    if (placeData) {
      setValue('name', placeData.name);
      setValue('timezoneCode', placeData.timezoneCode);
      setValue('stateName', placeData.stateName);
      setValue('stateCode', placeData.stateCode);
      setValue('countryCode', placeData.countryCode);
      setValue('countryName', placeData.countryName);
      setValue('trafficAvgSpeed', placeData.trafficAvgSpeed);
      setValue('gps', placeData.gps);
      trigger('name');
    } else {
      setValue('name', '');
      if (placeData === undefined) trigger('name');
    }
  }, [placeData]);

  const localCuisinesRef = useRef<TSelectRef>();

  const localCuisinesOptions = Object.values(RestaurantCuisine).map((cuisine) => ({
    label: cuisine,
    value: cuisine,
  }));

  return (
    <Wrapper>
      <FormWrapper>
        <FormProvider {...methods}>
          {!place && (
            <>
              <FormSectionHeader title={t('places.edit.searchCity')} />
              <PlaceSearchInput error={errors.name} onValueChange={setPlaceData} fullPlaceSearch />
            </>
          )}
          {placeData && (
            <PlaceEditWrapper>
              <PlaceDataText>
                {JSON.stringify({ ...placeData, id: place?.id }, null, 4)}
              </PlaceDataText>
              <FormSectionHeader
                title={t('places.edit.editValues')}
                description={t('places.edit.editValuesDescription')}
              />
              <FormInput
                testId="PlaceEdit#name"
                label={t('places.edit.name')}
                {...register('name')}
                error={errors.name}
                defaultValue={initialName}
              />
              <FormInput
                testId="PlaceEdit#timezoneCode"
                label={t('places.edit.timezone')}
                {...register('timezoneCode')}
                error={errors.timezoneCode}
                defaultValue={initialTimezoneCode}
              />
              <FormInput
                testId="PlaceEdit#stateName"
                label={t('places.edit.stateName')}
                {...register('stateName')}
                error={errors.stateName}
                defaultValue={initialStateName}
              />
              <FormInput
                testId="PlaceEdit#stateCode"
                label={t('places.edit.stateCode')}
                {...register('stateCode')}
                error={errors.stateCode}
                defaultValue={initialStateCode}
              />
              <FormInput
                testId="PlaceEdit#countryCode"
                label={t('places.edit.countryCode')}
                {...register('countryCode')}
                error={errors.countryCode}
                defaultValue={initialCountryCode}
              />
              <FormInput
                testId="PlaceEdit#countryName"
                label={t('places.edit.countryName')}
                {...register('countryName')}
                error={errors.countryName}
                defaultValue={initialCountryName}
              />
            </PlaceEditWrapper>
          )}
          <FormSectionHeader
            title={t('places.edit.setDashboardImages')}
            description={t('places.edit.dashboardImagesDescription')}
          />
          {/* FOR YOU */}
          <FormSectionHeader
            title={t('places.edit.dashboardImages')}
            description={t('places.edit.forYouImages')}
          />
          <FormUpload
            testId="PlaceEdit#dashboardImagesForYou"
            control={control}
            name="dashboardImages.forYou"
            t={t}
            dropzoneLabel={t('places.edit.dropForYouImages')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
            multiple
          />
          {/* LANDMARKS */}
          <FormSectionHeader
            title={t('places.edit.dashboardImages')}
            description={t('places.edit.landmarksImages')}
          />
          <FormUpload
            testId="PlaceEdit#dashboardImagesLandmarks"
            control={control}
            name="dashboardImages.landmarks"
            t={t}
            dropzoneLabel={t('places.edit.dropLandmarksImages')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
            multiple
          />
          {/* RESTAURANTS */}
          <FormSectionHeader
            title={t('places.edit.dashboardImages')}
            description={t('places.edit.restaurantsImages')}
          />
          <FormUpload
            testId="PlaceEdit#dashboardImagesRestaurants"
            control={control}
            name="dashboardImages.restaurants"
            t={t}
            dropzoneLabel={t('places.edit.dropRestaurantsImages')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
            multiple
          />
          {/* ATTRACTIONS */}
          <FormSectionHeader
            title={t('places.edit.dashboardImages')}
            description={t('places.edit.attractionsImages')}
          />
          <FormUpload
            testId="PlaceEdit#dashboardImagesAttractions"
            control={control}
            name="dashboardImages.attractions"
            t={t}
            dropzoneLabel={t('places.edit.dropAttractionsImages')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
            multiple
          />
          {/* EVENTS */}
          <FormSectionHeader
            title={t('places.edit.dashboardImages')}
            description={t('places.edit.eventsImages')}
          />
          <FormUpload
            testId="PlaceEdit#dashboardImagesEvents"
            control={control}
            name="dashboardImages.events"
            t={t}
            dropzoneLabel={t('places.edit.dropEventsImages')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
            multiple
          />

          <FormSectionHeader
            title={t('places.edit.setMapIntroImage')}
            description={t('places.edit.mapIntroImage')}
          />
          <FormUpload
            testId="PlaceEdit#mapIntroImage"
            control={control}
            name="mapIntroImage"
            t={t}
            dropzoneLabel={t('places.edit.dropMapIntroImage')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
            multiple
          />

          <FormSectionHeader
            title={t('places.edit.setBranding')}
            description={t('places.edit.logo')}
          />
          <FormUpload
            testId="PlaceEdit#imageLogo"
            control={control}
            name="branding.logo"
            t={t}
            dropzoneLabel={t('places.edit.dropLogo')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.png')}
          />
          <FormSectionHeader
            title={t('places.edit.setBranding')}
            description={`${t('places.edit.avatar')}\n${t('places.edit.avatarDescription')}`}
          />
          <FormUpload
            testId="PlaceEdit#imageAvatar"
            control={control}
            name="branding.avatar"
            t={t}
            dropzoneLabel={t('places.edit.dropAvatar')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.branding.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setBranding')}
            description={t('places.edit.background')}
          />
          <FormUpload
            testId="PlaceEdit#imageBackground"
            control={control}
            name="branding.background"
            t={t}
            dropzoneLabel={t('places.edit.dropBackground')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.branding.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setBranding')}
            description={t('places.edit.topTenBackground')}
          />
          <FormUpload
            testId="PlaceEdit#imageTopTenBackground"
            control={control}
            name="branding.topTenBackground"
            t={t}
            dropzoneLabel={t('places.edit.dropBackground')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.branding.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setSectionImages')}
            description={t('places.edit.attractionsImages')}
          />
          <FormUpload
            testId="PlaceEdit#imageattractions"
            control={control}
            name="sectionImages.attractions"
            t={t}
            dropzoneLabel={t('places.edit.dropattractios')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setSectionImages')}
            description={t('places.edit.museumsImage')}
          />
          <FormUpload
            testId="PlaceEdit#imagemuseums"
            control={control}
            name="sectionImages.museums"
            t={t}
            dropzoneLabel={t('places.edit.dropmuseums')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setSectionImages')}
            description={t('places.edit.theatreImage')}
          />
          <FormUpload
            testId="PlaceEdit#imagetheatre"
            control={control}
            name="sectionImages.theatre"
            t={t}
            dropzoneLabel={t('places.edit.droptheatre')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setSectionImages')}
            description={t('places.edit.concertsImage')}
          />
          <FormUpload
            testId="PlaceEdit#imageconcerts"
            control={control}
            name="sectionImages.concerts"
            t={t}
            dropzoneLabel={t('places.edit.dropconcerts')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setSectionImages')}
            description={t('places.edit.sportsImage')}
          />
          <FormUpload
            testId="PlaceEdit#imagesports"
            control={control}
            name="sectionImages.sports"
            t={t}
            dropzoneLabel={t('places.edit.dropsports')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setSectionImages')}
            description={t('places.edit.cellImage')}
          />
          <FormUpload
            testId="PlaceEdit#imagecell"
            control={control}
            name="sectionImages.cell"
            t={t}
            dropzoneLabel={t('places.edit.dropcell')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setWelcomeScreenImage')}
            description={t('places.edit.welcomeScreenImage')}
          />
          <FormUpload
            testId="PlaceEdit#imageWelcomeScreen"
            control={control}
            name="welcomeScreenImage"
            t={t}
            dropzoneLabel={t('places.edit.dropWelcomeScreenImage')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.setBadgesImage')}
            description={t('places.edit.badgesImage')}
          />
          <FormUpload
            testId="PlaceEdit#imageBadgesImage"
            control={control}
            name="badgesImage"
            t={t}
            dropzoneLabel={t('places.edit.dropBadgesImage')}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.image.illegal')}
          />

          <FormSectionHeader
            title={t('places.edit.additionalSettings')}
            description={t('places.edit.additionalSettingsDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="PlaceEdit#popularDestination"
            label={t('places.edit.popularDestination')}
            initialValue={initialValues.popularDestination ?? false}
            onChange={onPopularDestinationSwitchChange}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="PlaceEdit#hasDistricts"
            label={t('places.edit.placeHasDistricts')}
            initialValue={initialHasDistrict ?? false}
            onChange={onHasDistrictsSwitchChange}
            disabled={isPlaceDistrict}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="PlaceEdit#isDistrict"
            label={t('places.edit.placeIsDistrict')}
            initialValue={isPlaceDistrict}
            onChange={onIsDistrictSwitchChange}
            disabled={currentHasDistrictsValue}
          />
          {isPlaceDistrict && (
            <>
              {currentParentPlaceUuid?.value && (
                <FormInput
                  testId="PlaceEdit#parentPlaceUuid"
                  label={t('places.edit.parentPlaceUuid')}
                  value={getParentPlaceUuidWithPrefix(currentParentPlaceUuid?.value || '')}
                  readOnly
                  disabled
                />
              )}
              <FormSelect
                clearable
                searchable
                control={control}
                error={get(errors, 'parentPlaceUuid')}
                options={places || []}
                defaultValue={initialValues.parentPlaceUuid}
                name={'parentPlaceUuid'}
                label={t('places.edit.parentCity')}
                testId="InstitutionEdit#places"
                onMenuScrollToBottom={onPlacesMenuScrollToBottom}
                isLoading={isLoadingPlaces}
                onSearch={onPlacesSearch}
              />
              <FormInput
                testId="PlaceEdit#borough"
                label={t('places.edit.borough')}
                {...register('borough')}
                error={errors.borough}
                defaultValue={initialValues.borough}
              />
            </>
          )}
          <FormSectionHeader
            title={t('places.edit.additionalOptions')}
            description={t('places.edit.allowRoles')}
          />
          <FlexWrapper>
            {POSSIBLE_INSTITUTION_ROLES.map((group) => (
              <FormSwitcherWithLabel
                key={group}
                isVertical
                testId={`PlaceEdit#${group}`}
                label={t(`places.edit.${group.replace('_', '').toLowerCase()}`)}
                initialValue={(initalGroupsOfInstitutions || [])?.includes(group)}
                onChange={onChangeInstitution(group)}
              />
            ))}
          </FlexWrapper>
          <FormSectionHeader
            title={t('places.edit.disabledContentTitle')}
            description={t('places.edit.disabledContent')}
          />
          <FlexWrapper>
            {POSSIBLE_PLACE_CONTENT_CATEGORIES.map((group) => (
              <FormSwitcherWithLabel
                key={group}
                isVertical
                testId={`PlaceEdit#${group}`}
                label={t(`places.edit.${group.replace('_', '').toLowerCase()}`)}
                initialValue={(initialDisabledContent || [])?.includes(group)}
                onChange={onChangeDisabledGroupOfInstitution(group)}
              />
            ))}
          </FlexWrapper>
          <FormSectionHeader
            title={t('places.edit.placeInfo')}
            description={t('places.edit.placeInfoDescription')}
          />
          {place && defaultPlaceInstitutions.length > 0 && (
            <FormSelect
              isMulti
              name="institutions"
              isReadonly
              onClickItemCallback={redirectToInstitutionEdit}
              label={t('places.edit.institutions')}
              options={places || []}
              clearable={false}
              defaultValue={defaultPlaceInstitutions}
              testId="PlaceEdit#institutions"
            />
          )}
          {place && defaultPlaceUsers.length > 0 && (
            <FormSelect
              isMulti
              name="users"
              isReadonly
              onClickItemCallback={redirectToUserEdit}
              label={t('places.edit.users')}
              options={places || []}
              clearable={false}
              defaultValue={defaultPlaceUsers}
              testId="PlaceEdit#users"
            />
          )}
          <FormInput
            testId="PlaceEdit#email"
            label={t('places.edit.email')}
            {...register('reportsEmail')}
            error={errors.reportsEmail}
          />
          <FormTextarea
            testId="PlaceEdit#description"
            label={t('places.edit.description')}
            {...register('description')}
            error={errors.description}
          />
          <FormWysiwyg
            testId="PlaceEdit#wysiwyg"
            control={control}
            name="about"
            label={t('places.edit.information')}
            initialValue={JSON.parse(getValues('about') || SERIALIZED_WYSIWYG_EMPTY_VALUE)}
          />
          <FlexWrapper>
            <FormInput
              testId="PlaceEdit#announcementRange"
              min="1"
              type="number"
              label={t('places.edit.announcementRange')}
              info={t('places.edit.announcementRangeInfo')}
              {...register(`announcementRange`)}
              error={errors.announcementRange}
            />
            <VerticalDivider />
            <FormInput
              testId="PlaceEdit#eventRange"
              min="1"
              type="number"
              label={t('places.edit.eventRange')}
              info={t('places.edit.eventRangeInfo')}
              {...register(`eventRange`)}
              error={errors.eventRange}
            />
          </FlexWrapper>
          <FormInput
            testId="PlaceEdit#centerMapMinDistance"
            min="1"
            type="number"
            label={t('places.edit.centerMapMinDistance')}
            info={t('places.edit.centerMapMinDistancenfo')}
            {...register(`centerMapMinDistance`)}
            error={errors.centerMapMinDistance}
          />
          <FormInput
            testId="PlaceEdit#trafficAvgSpeed"
            label={t('places.edit.trafficAvgSpeed')}
            {...register('trafficAvgSpeed')}
            error={errors.trafficAvgSpeed}
            defaultValue={initialValues.trafficAvgSpeed}
          />
          <FormSectionHeader
            title={t('places.edit.visitorModeSection')}
            description={t('places.edit.visitorModeSectionDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="PlaceEdit#visitorMode"
            label={t('places.edit.visitorMode')}
            initialValue={initialValues.visitorMode || false}
            onChange={onVisitorModeSwitchChange}
          />
          <FormSectionHeader
            title={t('places.edit.itineraryModeSection')}
            description={t('places.edit.iitneraryModeSectionDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="PlaceEdit#itineraryMode"
            label={t('places.edit.itineraryMode')}
            initialValue={initialValues.itineraryMode || false}
            onChange={onItineraryModeSwitchChange}
          />
          <FormSectionHeader
            title={t('places.edit.isOgCitySection')}
            description={t('places.edit.isOgCitySectionDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="PlaceEdit#isOgCity"
            label={t('places.edit.isOgCity')}
            initialValue={initialValues.isOgCity || false}
            onChange={onIsOgCitySwitchChange}
          />
          <FormSectionHeader
            title={t('places.edit.residentModeSection')}
            description={t('places.edit.residentModeSectionDescription')}
          />
          <FormSwitcherWithLabel
            isVertical
            testId="PlaceEdit#residentMode"
            label={t('places.edit.residentMode')}
            initialValue={initialValues.residentMode || false}
            onChange={onResidentModeSwitchChange}
          />
          <FormInput
            testId="PlaceEdit#visitorWelcomeVideoUrl"
            label={t('places.edit.visitorWelcomeVideoUrl')}
            {...register(`visitorWelcomeVideoUrl`)}
            error={errors.visitorWelcomeVideoUrl}
          />
          <FormSelect
            control={control}
            label={t('places.edit.localCuisines')}
            name={'localCuisines'}
            error={get(errors, 'localCuisines')}
            options={localCuisinesOptions}
            defaultValue={initialValues.localCuisines}
            ref={localCuisinesRef}
            testId="PlaceEdit#localCuisines"
            isMulti
          />
          {place?.createdAt && (
            <FormSectionHeader
              description={t('places.edit.createdAt', {
                dateTime: formatDateObject(new Date(place.createdAt)),
              })}
            />
          )}
          <ButtonsWrapper>
            <Button
              testId="PlaceEdit#submit"
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
            >
              {place ? t('common.save') : t('common.add')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </FormWrapper>
    </Wrapper>
  );
};

export default PlaceEdit;
